<template>
    <div>
        <div>
            <!-- Type of user table -->

            <CDataTable
                :hover="hover"
                :striped="striped"
                :border="border"
                :small="small"
                :fixed="fixed"
                :items="items1"
                :fields="fields1"
                :items-per-page="small ? 5 : 5"
                :dark="dark"
                pagination
            >
                <template #Action="{ item }">
                    <td class="d-flex">
                        <router-link
                            :to="{
                                name: 'Edit type of user details',
                                params: { item },
                            }"
                        >
                            <CButton
                                color="secondary"
                                size="sm"
                                class="mr-2 mb-2"
                                :id="item.id"
                                ><CIcon name="cil-pencil"
                            /></CButton>
                        </router-link>
                        <CButton
                            color="danger"
                            size="sm"
                            class="mr-2 mb-2"
                            :id="item.id"
                            @click="openModal1(item)"
                            ><CIcon name="cil-trash"
                        /></CButton>
                    </td>
                </template>

                <template #Status="{ item }">
                    <td>
                        <CSwitch
                            class="mt-1 ml-2 mr-2"
                            color="primary"
                            :id="item.id"
                            shape="pill"
                        />
                    </td>
                </template>
            </CDataTable>


            <!-- Farmer category table  -->

            <CDataTable
                :hover="hover"
                :striped="striped"
                :border="border"
                :small="small"
                :fixed="fixed"
                :items="items2"
                :fields="fields2"
                :items-per-page="small ? 5 : 5"
                :dark="dark"
                pagination
            >
                <template #Status="{ item }">
                    <td>
                        <CSwitch
                            class="mt-1 ml-2 mr-2"
                            color="primary"
                            :id="item.id"
                            shape="pill"
                        />
                    </td>
                </template>
                <template #Action="{ item }">
                    <td class="d-flex">
                        <router-link
                            :to="{
                                name: 'Edit new farmer category',
                                params: { item },
                            }"
                        >
                        <CButton
                            color="secondary"
                            size="sm"
                            class="mr-2 mb-2"
                            :id="item.id"
                            ><CIcon name="cil-pencil"
                        /></CButton>
                        </router-link>
                        <CButton
                            color="danger"
                            size="sm"
                            class="mr-2 mb-2"
                            :id="item.id"
                            @click="openModal(item)"
                            ><CIcon name="cil-trash"
                        /></CButton>
                    </td>
                </template>
            </CDataTable>
        </div>

        <!-- Popup for deleting data -->
        
        <div>
        <CModal
            title="Delete user type"
            :show.sync="myModal1"
            size="sm"
            >
            Are you sure you want to proceed?
            <template #footer>
                <CButton @click="myModal1= false" color="danger">Cancel</CButton>
                <CButton @click="deteteCompanyCategory()" color="success">Delete</CButton>
        </template>
        </CModal>

        <CModal
            title="Delete Category"
            :show.sync="myModal"
            size="sm"
            >
            Are you sure you want to proceed?
            <template #footer>
                <CButton @click="myModal= false" color="danger">Cancel</CButton>
                <CButton @click="deteteFarmerCategory()" color="success">Delete</CButton>
        </template>
        </CModal>
        </div>

    <div>
        <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.error_message }}
            </CAlert>
    </div>


    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const companyCategoryUrl = URL + `${"profile/bank/bashboard/settings/"}`;
const farmerCategoryUrl = URL + `${"profile/farmer/category/"}`;

export default {
    name: "MasterTable",

    data() {
        return {
            myModal1: false,
            deleteElement1: null,


            myModal: false,
            deleteElement: null,

            checkError: false,
            errorMessage: "",

        }
    },

    methods: {
        editUserType() {
            this.$router.push("/editTypeofUser");
        },


        deteteCompanyCategory() {
            this.axios
                .delete(companyCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement1.id },
                })
                .then((response) => {
                    this.deleteElement1= null;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },


        deteteFarmerCategory() {
            this.axios
                .delete(farmerCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement.id },
                })
                .then((response) => {
                    this.deleteElement= null;
                })
                .catch((error) => {
                    this.checkError = true
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        openModal1(item){
            this.myModal1= true;
            this.deleteElement1= item;
        },

        openModal(item){
            this.myModal= true;
            this.deleteElement= item;
        }
    },

    props: {
        items1: Array,
        items2: Array,

        fields1: {
            type: Object,
            default() {
                return {
                    items: Array,
                };
            },
        },

        fields2: {
            type: Array,
            default() {
                return {
                    items: Array,
                };
            },
        },

        caption: {
            type: String,
            default: "MasterTable",
        },
        hover: Boolean,
        striped: Boolean,
        border: Boolean,
        small: Boolean,
        fixed: Boolean,
        dark: Boolean,
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
};
</script>
