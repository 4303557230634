var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items1,"fields":_vm.fields1,"items-per-page":_vm.small ? 5 : 5,"dark":_vm.dark,"pagination":""},scopedSlots:_vm._u([{key:"Action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                            name: 'Edit type of user details',
                            params: { item: item },
                        }}},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"secondary","size":"sm","id":item.id}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1),_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal1(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}},{key:"Status",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_c('CSwitch',{staticClass:"mt-1 ml-2 mr-2",attrs:{"color":"primary","id":item.id,"shape":"pill"}})],1)]}}])}),_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items2,"fields":_vm.fields2,"items-per-page":_vm.small ? 5 : 5,"dark":_vm.dark,"pagination":""},scopedSlots:_vm._u([{key:"Status",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_c('CSwitch',{staticClass:"mt-1 ml-2 mr-2",attrs:{"color":"primary","id":item.id,"shape":"pill"}})],1)]}},{key:"Action",fn:function(ref){
                        var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                            name: 'Edit new farmer category',
                            params: { item: item },
                        }}},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"secondary","size":"sm","id":item.id}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1),_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1),_c('div',[_c('CModal',{attrs:{"title":"Delete user type","show":_vm.myModal1,"size":"sm"},on:{"update:show":function($event){_vm.myModal1=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.myModal1= false}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.deteteCompanyCategory()}}},[_vm._v("Delete")])]},proxy:true}])},[_vm._v(" Are you sure you want to proceed? ")]),_c('CModal',{attrs:{"title":"Delete Category","show":_vm.myModal,"size":"sm"},on:{"update:show":function($event){_vm.myModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.myModal= false}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.deteteFarmerCategory()}}},[_vm._v("Delete")])]},proxy:true}])},[_vm._v(" Are you sure you want to proceed? ")])],1),_c('div',[_c('CAlert',{staticClass:"alert-dismissible",attrs:{"color":"danger","show":_vm.checkError},on:{"update:show":function($event){_vm.checkError=$event}}},[_vm._v(" "+_vm._s(this.error_message)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }