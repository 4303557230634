<template>
    <div>

        <!-- Alert for error -->

        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>


        <!-- Type of user and farmer category tables -->

        <div>
            <CCard>
                <CRow class="mt-4 mr-3">
                    <CCol sm="5">
                        <h5 class="ml-4">Type of User</h5>
                    </CCol>

                    <CCol sm="7" class="d-none d-md-block">
                        <router-link to="/newTypeUser">
                            <CButton
                                color="primary"
                                shape="pill"
                                class="float-right"
                                >+</CButton
                            >
                        </router-link>
                    </CCol>
                </CRow>

                <CCardBody>
                    <div>
                        <CDataTable
                            :hover="true"
                            :striped="true"
                            :border="true"
                            :small="small"
                            :fixed="true"
                            :items="items1"
                            :fields="fields1"
                            :items-per-page="small ? 5 : 5"
                            pagination
                        >
                            <template #Action="{ item }">
                                <td class="d-flex">
                                    <router-link
                                        :to="{
                                            name: 'Edit type of user details',
                                            params: { item },
                                        }"
                                    >
                                        <CButton
                                            color="secondary"
                                            size="sm"
                                            class="mr-2 mb-2"
                                            :id="item.id"
                                            ><CIcon name="cil-pencil"
                                        /></CButton>
                                    </router-link>
                                    <CButton
                                        color="danger"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="openModal1(item)"
                                        ><CIcon name="cil-trash"
                                    /></CButton>
                                </td>
                            </template>

                            <template #Status="{ item }">
                                <td>
                                    <CSwitch
                                        class="mt-1 ml-2 mr-2"
                                        color="primary"
                                        :id="item.id"
                                        shape="pill"
                                    />
                                </td>
                            </template>
                        </CDataTable>
                    </div>
                </CCardBody>

                <CRow class="mt-4 mr-3">
                    <CCol sm="5">
                        <h5 class="ml-4">Farmer Category</h5>
                    </CCol>

                    <CCol sm="7" class="d-none d-md-block">
                        <router-link to="/newFarmerCategory">
                            <CButton
                                color="primary"
                                shape="pill"
                                class="float-right"
                                >+</CButton
                            >
                        </router-link>
                    </CCol>
                </CRow>

                <CCardBody>
                    <div class="table-responsive">
                        <CDataTable
                            :hover="true"
                            :striped="true"
                            :border="true"
                            :small="small"
                            :fixed="true"
                            :items="items2"
                            :fields="fields2"
                            :items-per-page="small ? 5 : 5"
                            pagination
                        >
                            <template #Status="{ item }">
                                <td>
                                    <CSwitch
                                        class="mt-1 ml-2 mr-2"
                                        color="primary"
                                        :id="item.id"
                                        shape="pill"
                                    />
                                </td>
                            </template>
                            <template #Action="{ item }">
                                <td class="d-flex">
                                    <router-link
                                        :to="{
                                            name: 'Edit new farmer category',
                                            params: { item },
                                        }"
                                    >
                                        <CButton
                                            color="secondary"
                                            size="sm"
                                            class="mr-2 mb-2"
                                            :id="item.id"
                                            ><CIcon name="cil-pencil"
                                        /></CButton>
                                    </router-link>
                                    <CButton
                                        color="danger"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="openModal(item)"
                                        ><CIcon name="cil-trash"
                                    /></CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </div>
                </CCardBody>
            </CCard>
        </div>

        <!-- Popup of deleting data -->
        <div>
            <CModal title="Delete user type" :show.sync="myModal1" size="sm">
                Are you sure you want to proceed?
                <template #footer>
                    <CButton @click="myModal1 = false" color="danger"
                        >Cancel</CButton
                    >
                    <CButton @click="deteteCompanyCategory()" color="success"
                        >Delete</CButton
                    >
                </template>
            </CModal>

            <CModal title="Delete Category" :show.sync="myModal" size="sm">
                Are you sure you want to proceed?
                <template #footer>
                    <CButton @click="myModal = false" color="danger"
                        >Cancel</CButton
                    >
                    <CButton @click="deteteFarmerCategory()" color="success"
                        >Delete</CButton
                    >
                </template>
            </CModal>
        </div>

    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const companyCategoryUrl = URL + `${"profile/bank/bashboard/settings/"}`;
const farmerCategoryUrl = URL + `${"profile/farmer/category/"}`;

import CustomTable from "../../admin/master/MasterTable.vue";

export default {
    name: "Bank",
    components: { CustomTable },
    data() {
        return {
            myModal1: false,
            deleteElement1: null,

            myModal: false,
            deleteElement: null,

            items1: [],
            items2: [],

            errorMessage: "",
            checkError: false,


            fields1: [
                {
                    key: "category",
                    label: "User Type",
                },
                {
                    key: "dashboard_data",
                    label: "Graph",
                },
                {
                    key: "Action",
                    label: "Action",
                },
            ],
            fields2: [
                {
                    key: "label",
                    label: "Category",
                },
                {
                    key: "Action",
                    label: "Action",
                },
            ],
        };
    },

    methods: {
        getCompanyCategoryData() {
            return this.items1;
        },
        getFarmerCategoryData() {
            return this.items2;
        },
        getCompanyData() {
            this.axios
                .get(companyCategoryUrl, {
                    params: { id: "all" },
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.items1 = response.data.context;
                })
                .catch((error) => {
                    this.items1 = [];
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
        getFarmerData() {
            this.axios
                .get(farmerCategoryUrl, {
                    params: { id: "all" },
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.items2 = response.data.context;
                })
                .catch((error) => {
                    this.items2 = [];
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        },
        getShuffledUsersData2() {
            return this.shuffleArray(categoryData.slice(0));
        },

        editUserType() {
            this.$router.push("/editTypeofUser");
        },

        deteteCompanyCategory() {
            this.axios
                .delete(companyCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement1.id },
                })
                .then((response) => {
                    this.deleteElement1 = null;
                    this.myModal1 = false, this.getCompanyData();
                })
                .catch((error) => {
                    this.items1 = [];
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        deteteFarmerCategory() {
            this.axios
                .delete(farmerCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement.id },
                })
                .then((response) => {
                    this.deleteElement = null;
                    this.myModal = false, 
                    this.getFarmerData();
                })
                .catch((error) => {
                    this.items2 = [];
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        openModal1(item) {
            this.myModal1 = true;
            this.deleteElement1 = item;
        },

        openModal(item) {
            this.myModal = true;
            this.deleteElement = item;
        },
    },
    beforeMount() {
        this.getCompanyData();
        this.getFarmerData();
    },

    props: {
        small: Boolean,
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
};
</script>
